import axios from "axios";

// import Button from "@mui/material/Button";
import {
  CustomLinkButton,
  CustomArchivingButton,
  CustomDeleteButton,
} from "../../components";
// import { da } from "date-fns/locale";

export const routeTable = "sanctions";
export const route = "sanction";
export const nameTable = "Sanctions";

export const days = [
  { label: "Manuel", value: "Manuel", days: null },
  { label: "1 jour", value: "1 jour", days: 0 },
  { label: "1 semaine", value: "1 semaine", days: 6 },
  { label: "2 semaines", value: "2 semaines", days: 13 },
  { label: "3 semaines", value: "3 semaines", days: 20 },
  { label: "1 mois", value: "1 mois", days: 30 },
  { label: "2 mois", value: "2 mois", days: 50 },
  { label: "3 mois", value: "3 mois", days: 90 },
  { label: "4 mois", value: "4 mois", days: 120 },
  { label: "5 mois", value: "5 mois", days: 150 },
  { label: "6 mois", value: "6 mois", days: 180 },
  { label: "7 mois", value: "7 mois", days: 210 },
  { label: "8 mois", value: "8 mois", days: 240 },
  { label: "9 mois", value: "9 mois", days: 270 },
  { label: "10 mois", value: "10 mois", days: 300 },
  { label: "11 mois", value: "11 mois", days: 330 },
  { label: "1 an", value: "1 an", days: 365 },
  { label: "13 mois", value: "13 mois", days: 395 },
  { label: "14 mois", value: "14 mois", days: 425 },
  { label: "15 mois", value: "15 mois", days: 456 },
  { label: "16 mois", value: "16 mois", days: 486 },
  { label: "17 mois", value: "17 mois", days: 517 },
  { label: "18 mois", value: "18 mois", days: 547 },
  { label: "19 mois", value: "19 mois", days: 577 },
  { label: "20 mois", value: "20 mois", days: 608 },
  { label: "2 ans", value: "2 ans", days: 730 },
  { label: "3 ans", value: "3 ans", days: 1095 },
  { label: "4 ans", value: "4 ans", days: 1460 },
  { label: "5 ans", value: "5 ans", days: 1825 },
  { label: "6 ans", value: "6 ans", days: 2195 },
  { label: "7 ans", value: "7 ans", days: 2555 },
  { label: "8 ans", value: "8 ans", days: 2920 },
  { label: "9 ans", value: "9 ans", days: 3285 },
  { label: "10 ans", value: "10 ans", days: 3650 },
  { label: "à vie", value: "à vie", days: 18250 },
];

//shortlist
export async function getShortlists(setOptions) {
  let fautes = [];
  let cacheFautes = localStorage.getItem("fautes");
  if (!cacheFautes) {
    axios
      .get(process.env.REACT_APP_API_URL + "fautes", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        let data = r.data;
        for (let i in data) {
          console.log(data[i]);
          fautes.push({
            label: data[i]?.fault_code,
            value: data[i]?.id,
            helperText:
              "<strong>Faute</strong> : " +
              data[i]?.fault_description +
              "<br/><br/><strong>Catégorie</strong> : " +
              data[i]?.fault_category_description +
              " <br/><br/><strong>Pénalité :</strong> " +
              data[i]?.penalty,
            fault_description: data[i]?.fault_description,
            fault_category_description: data[i]?.fault_category_description,
            penalty: data[i]?.penalty,
          });
        }
        localStorage.setItem("fautes", JSON.stringify(fautes));
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    fautes = JSON.parse(cacheFautes);
  }
  setOptions({
    fault_code_1_id: fautes,
    fault_code_2_id: fautes,
  });
  // End shortlist
}

// Fonction pour rechercher des informations basées sur la date
const fetchEventData = async (date, setData, data) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}search_by_date/sanctions`;
    const response = await axios.get(apiUrl, {
      params: { date },
      headers: { authorization: localStorage.getItem("api_token") },
    });
    const eventData = response.data;

    let rencontres_list = [];

    if (Array.isArray(eventData)) {
      rencontres_list = (eventData || []).map((rencontre) => ({
        label: `${rencontre.team_1_name} vs ${
          rencontre.team_2_name
        } - ${new Date(rencontre.match_date).toLocaleDateString()}`,
        value: rencontre.id,
      }));
    }
    setData({
      ...data,
      rencontre_id: { ...data["rencontre_id"], values: rencontres_list },
      event_date: { ...data["event_date"], value: date },
      // Mettez à jour cette ligne en fonction des données retournées par votre API
    });
  } catch (error) {
    console.error(
      "Erreur lors de la recherche des données de l'événement",
      error
    );

    if (error.response) {
      console.error("Data received from the server:", error.response.data);
      console.error("Status:", error.response.status);
      console.error("Headers:", error.response.headers);
    } else if (error.request) {
      console.error("Request made but no response received:", error.request);
    } else {
      console.error("Error in setting up the request:", error.message);
    }
  }
};

const fetchEquipeData = async (rencontre_id, setData, data) => {
  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}search_by_rencontre/sanctions`;
    const response = await axios.get(apiUrl, {
      params: { rencontre_id },
      headers: { authorization: localStorage.getItem("api_token") },
    });
    const eventData = response.data;

    let equipes_list = [];

    if (Array.isArray(eventData)) {
      equipes_list = (eventData || []).map((equipe) => ({
        label: `${equipe.name}`,
        value: equipe.equipe_id,
      }));
    }
    setData({
      ...data,
      equipe_id: { ...data["equipe_id"], values: equipes_list },
      rencontre_id: { ...data["rencontre_id"], value: rencontre_id },
      // Mettez à jour cette ligne en fonction des données retournées par votre API
    });
  } catch (error) {
    console.error(
      "Erreur lors de la recherche des données de l'événement",
      error
    );

    if (error.response) {
      console.error("Data received from the server:", error.response.data);
      console.error("Status:", error.response.status);
      console.error("Headers:", error.response.headers);
    } else if (error.request) {
      console.error("Request made but no response received:", error.request);
    } else {
      console.error("Error in setting up the request:", error.message);
    }
  }
};

const fetchMembresData = async (equipe_id, setData, data) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "membres/shortlist/affiliations",
      {
        headers: { authorization: localStorage.getItem("api_token") },
      }
    );

    // Convert the object of objects to an array of objects
    const data_request = Object.values(response.data);

    // Map through the array of objects
    let membres = [];
    if (equipe_id) {
      membres = data_request
        .map((item) => ({
          label: `${item.name.toUpperCase()} ${item.first_name}`,
          value: item.id,
          equipe_id: item.equipe_id,
          registration_number: item.registration_number,
          affiliation_id: item.affiliation_id,
        }))
        .filter((item) => item.equipe_id === equipe_id);
    } else {
      membres = data_request.map((item) => ({
        label: `${item.name.toUpperCase()} ${item.first_name}`,
        value: item.id,
        equipe_id: item.equipe_id,
        registration_number: item.registration_number,
      }));
    }

    // Update the state with the new data
    setData({
      ...data,
      equipe_id: { ...data.equipe_id, value: equipe_id },
      membre_id: { ...data.membre_id, values: membres },
    });
  } catch (error) {
    console.error("Error fetching membres data:", error);
  }
};

export const struct = (data = {}, setData) => {
  let membre_name = "";
  let membre_id = "";

  if (data?.affiliation || data?.membre) {
    membre_name = "";
    if (data?.membre?.first_name && data?.membre?.name && data?.membre?.id) {
      membre_name += data.membre.name + " " + data.membre.first_name;
      membre_id = data.membre.id;
    }
  }

  let dataConvocate = {};
  try {
    dataConvocate = data.data_convocate ? JSON.parse(data.data_convocate) : {};
  } catch (e) {
    console.error("Invalid JSON string", e);
  }

  let faute_1 = "";
  let faute_1_helperText = "";
  let faute_2 = "";
  let faute_2_helperText = "";

  if (data?.faute_1) {
    faute_1 = data?.faute_1?.fault_code || "";
    faute_1_helperText =
      "<strong>Faute</strong> : " +
      (data?.faute_1?.fault_description || "") +
      "<br/><br/><strong>Catégorie</strong> : " +
      (data?.faute_1?.fault_category_description || "") +
      " <br/><br/><strong>Pénalité :</strong> " +
      (data?.faute_1?.penalty || "");
  }
  if (data?.faute_2) {
    faute_2 = data?.faute_2?.fault_code || "";
    faute_2_helperText =
      "<strong>Faute</strong> : " +
      (data?.faute_2?.fault_description || "") +
      "<br/><br/><strong>Catégorie</strong> : " +
      (data?.faute_2?.fault_category_description || "") +
      " <br/><br/><strong>Pénalité :</strong> " +
      (data?.faute_2?.penalty || "");
  }

  return {
    fault_code_1_id: {
      label: "Code faute #1",
      value: {
        label: faute_1,
        value: data?.fault_code_1_id,
        helperText: faute_1_helperText,
      },
      type: "autocomplete",
      max: "unlimited",
    },
    fault_code_2_id: {
      label: "Code faute #2",
      value: {
        label: faute_2,
        value: data?.fault_code_2_id,
        helperText: faute_2_helperText,
      },
      type: "autocomplete",
      max: "unlimited",
    },
    event_date: {
      label: "Rencontre du",
      value: data?.event_date || null,
      type: "date2",
      onChange: async (value, data, setData) => {
        // Créez une date à partir de la valeur en considérant le fuseau horaire local
        const selectedDate = new Date(value);
        const formattedDate = new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())).toISOString().split("T")[0]; // Formater la date en YYYY-MM-DD
        await fetchEventData(formattedDate, setData, data);
      },
    },
    rencontre_id: {
      label: "Rencontres liée",
      type: "select",
      disabled: Boolean(data?.rencontre),
      value: data?.rencontre_id || "",
      values: [
        {
          label: `${data?.rencontre?.team_1_name} vs ${
            data?.rencontre?.team_2_name
          } - ${new Date(data?.rencontre?.match_date).toLocaleDateString()}`,
          value: data?.rencontre?.id,
        },
      ],
      onChange: async (value, data, setData) => {
        await fetchEquipeData(value, setData, data);
      },
    },
    equipe_id: {
      label: "Equipe liée",
      disabled: Boolean(data?.equipe_name),
      type: "select",
      value: data?.equipe_id || "",
      values: [
        {
          label: data?.equipe_name,
          value: data?.equipe_id,
        },
      ],
      onChange: async (value, data, setData) => {
        await fetchMembresData(value, setData, data);
      },
    },
    membre_id: {
      label: "Membre associé",
      type: "select",
      value: data?.membre_id,
      disabled: Boolean(membre_name),
      values: [
        {
          label: membre_name,
          value: membre_id,
        },
      ],
      onChange: (value, data, setData) => {
        if (value) {
          let membre = data?.membre_id?.values.find((m) => m.value === value);
          console.log(membre);
          setData({
            ...data,
            membre_id: {
              ...data["membre_id"],
              value: value,
            },
            affiliation_id: {
              ...data["affiliation_id"],
              value: membre?.affiliation_id || "",
            },
          });
        }
      },
    },
    affiliation_id: {
      label: "Affiliation n°",
      type: "text",
      value: data?.affiliation_id || "",
      disabled: true,
    },
    suspension_start: {
      label: "Début de suspension",
      value: data?.suspension_start || null,
      type: "date",
      onChange: (value, data, setData) => {
        setData({
          ...data,
          suspension_start: {
            ...data["suspension_start"],
            value: value,
          },
          sanction: {
            ...data["sanction"],
            value: undefined,
          },
        });
      },
    },
    suspension_end: {
      label: "Fin de suspension",
      value: data?.suspension_end || null,
      type: "date",
      onChange: (value, data, setData) => {
        setData({
          ...data,
          suspension_end: {
            ...data["suspension_end"],
            value: value,
          },
          sanction: {
            ...data["sanction"],
            value: undefined,
          },
        });
      },
    },
    color: {
      label: "Couleur",
      type: "select",
      value: data?.color || "",
      values: [
        { label: "Rouge", value: "R" },
        { label: "Jaune", value: "J" },
      ],
    },
    type: {
      label: "Type",
      type: "select",
      value: data?.type || "",
      values: [
        { label: "Simple jaune", value: "J1" },
        { label: "Double jaune", value: "J2" },
        { label: "Triple jaune", value: "J3" },
        { label: "Rouge", value: "R" },
      ],
    },
    count: {
      label: "Nombre",
      type: "number",
      value: data?.count || null,
    },
    sanction: {
      label: "Sanction",
      value: data?.sanction || "",
      type: "select",
      values: days,
      onChange: (value, data, setData) => {
        let day = days.find((d) => d?.value === value);
        let addDays = day?.days || 0;
        let date = data?.suspension_start?.value;
        let newDate = new Date(date);
        newDate.setDate(newDate.getDate() + addDays);
        setData({
          ...data,
          suspension_end: {
            ...data["suspension_end"],
            value: newDate,
          },
          sanction: {
            ...data["sanction"],
            value: value,
          },
        });
      },
    },
    committee: {
      label: "Comité",
      value: data?.committee || "",
      type: "select",
      values: [
        {
          label: "CGAC",
          value: "CGAC",
        },
        {
          label: "Comité Sportif",
          value: "CS",
        },
        {
          label: "Comité d'Appel",
          value: "CA",
        },
      ],
    },
    committee_date: {
      label: "Date de CGAC",
      type: "date",
      value: data?.committee_date || null,
    },
    appel_date: {
      label: "Date d'appel",
      type: "date",
      value: data?.appel_date || null,
    },
    folder_number: {
      label: "N° de dossier",
      type: "text",
      value: data?.folder_number || "",
      // disabled: !data?.folder_number ? true : false,
    },
    /*session_number: {
      label : "N° de session",
      type: "number",
      value: data?.session_number ? data?.session_number : null,
    },
    */
    /*sanctioned: {
      type: "checkbox",
      label: "Sanctionné",
      checked: Boolean(data?.sanctioned),
    },*/
    preventive: {
      type: "checkbox",
      label: "Préventive",
      checked: Boolean(data?.preventive),
    },
    afn: {
      type: "checkbox",
      label: "AFN",
      checked: Boolean(data?.afn),
    },
    recidivism: {
      type: "checkbox",
      label: "Récidive",
      checked: Boolean(data?.recidivism),
    },
    racism: {
      type: "checkbox",
      label: "Racisme",
      checked: Boolean(data?.racism),
    },
    referee_present: {
      type: "select",
      label: "Arbitre présent",
      value: data?.referee_present || "",
      values: [
        {
          label: "Absent",
          value: "A",
        },
        {
          label: "Excusé",
          value: "E",
        },
        {
          label: "Présent",
          value: "P",
        },
      ],
    },
    sanction_description: {
      type: "textarea",
      label: "Description de la sanction",
      value: data?.sanction_description || "",
    },
    note: {
      type: "textarea",
      label: "Résumé",
      value: data?.note || "",
    },
    fine: {
      type: "number",
      label: "Amende (€)",
      value: data?.fine || 0,
      onChange: (value, data, setData) => {
        setData({
          ...data,
          fine: {
            ...data["fine"],
            value: parseFloat(value?.target?.value || 0),
          },
          total_fees: {
            ...data["total_fees"],
            value:
              parseFloat(data["arbitration_fees"]?.value || 0) +
              parseFloat(value?.target?.value || 0) +
              parseFloat(data["miscellaneous_costs"]?.value || 0),
          },
        });
      },
    },
    arbitration_fees: {
      type: "number",
      label: "Frais d'arbitrage (€)",
      value: data?.arbitration_fees || 0,
      onChange: (value, data, setData) => {
        setData({
          ...data,
          arbitration_fees: {
            ...data["arbitration_fees"],
            value: parseFloat(value?.target?.value || 0),
          },
          total_fees: {
            ...data["total_fees"],
            value:
              parseFloat(value?.target?.value || 0) +
              parseFloat(data["fine"]?.value || 0) +
              parseFloat(data["miscellaneous_costs"]?.value || 0),
          },
        });
      },
    },
    miscellaneous_costs: {
      type: "number",
      label: "Frais divers (€)",
      value: data?.miscellaneous_costs || 0,
      onChange: (value, data, setData) => {
        setData({
          ...data,
          miscellaneous_costs: {
            ...data["miscellaneous_costs"],
            value: parseFloat(value?.target?.value || 0),
          },
          total_fees: {
            ...data["total_fees"],
            value:
              parseFloat(data["arbitration_fees"]?.value || 0) +
              parseFloat(data["fine"]?.value || 0) +
              parseFloat(value?.target?.value || 0),
          },
        });
      },
    },
    total_fees: {
      disabled: true,
      type: "number",
      label: "Coût total (€)",
      value:
        parseFloat(data?.arbitration_fees || 0) +
        parseFloat(data?.fine || 0) +
        parseFloat(data?.miscellaneous_costs || 0),
    },
    after: {
      type: "select",
      label: "Décision",
      value: data?.after || "",
      values: [
        {
          label: "Accepté",
          value: "accepte",
        },
        {
          label: "Refusé",
          value: "refuse",
        },
      ],
    },
    next_date: {
      label: "Date de sportif",
      type: "date",
      value: data?.next_date || null,
    },
    official_organ_number: {
      label: "Numéro d'organe officiel",
      type: "number",
      value: data?.official_organ_number || "",
    },
    official_organ_date: {
      label: "Date d'organe officiel",
      type: "date",
      value: data?.official_organ_date || null,
    },
    space: {
      type: "space",
    },
    is_convocate: {
      type: "checkbox",
      label: "Personnes convoquées?",
      checked: Boolean(data?.is_convocate),
    },
    rapport: {
      type: "checkbox",
      label: "Rapport arbitre",
      checked: Boolean(data?.rapport_arbitre),
    },
    member_present: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present: e.target.value,
        });
      },
    },
    person_name: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name = value;
          return updatedData;
        });
      },
    },
    text_convocate: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate || "",
    },
    role_convocate: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },
    member_present2: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present2 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present2: e.target.value,
        });
      },
    },
    person_name2: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name2 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name2 = value;
          return updatedData;
        });
      },
    },
    text_convocate2: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate2 || "",
    },
    role_convocate2: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate2 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate2: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },

    member_present3: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present3 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present3: e.target.value,
        });
      },
    },
    person_name3: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name3 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name3 = value;
          return updatedData;
        });
      },
    },
    text_convocate3: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate3 || "",
    },
    role_convocate3: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate3 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate3: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },

    member_present4: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present4 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present4: e.target.value,
        });
      },
    },
    person_name4: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name4 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name4 = value;
          return updatedData;
        });
      },
    },
    text_convocate4: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate4 || "",
    },
    role_convocate4: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate4 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate4: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },

    member_present5: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present5 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present5: e.target.value,
        });
      },
    },
    person_name5: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name5 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name5 = value;
          return updatedData;
        });
      },
    },
    text_convocate5: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate5 || "",
    },
    role_convocate5: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate5 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate5: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },

    member_present6: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present6 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present6: e.target.value,
        });
      },
    },
    person_name6: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name6 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name6 = value;
          return updatedData;
        });
      },
    },
    text_convocate6: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate6 || "",
    },
    role_convocate6: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate6 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate6: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },
    member_present7: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present7 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present7: e.target.value,
        });
      },
    },
    person_name7: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name7 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name7 = value;
          return updatedData;
        });
      },
    },
    text_convocate7: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate7 || "",
    },
    role_convocate7: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate7 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate7: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },
    member_present8: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present8 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present8: e.target.value,
        });
      },
    },
    person_name8: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name8 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name8 = value;
          return updatedData;
        });
      },
    },
    text_convocate8: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate8 || "",
    },
    role_convocate8: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate8 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate8: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },
    member_present9: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present9 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present9: e.target.value,
        });
      },
    },
    person_name9: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name9 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name9 = value;
          return updatedData;
        });
      },
    },
    text_convocate9: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate9 || "",
    },
    role_convocate9: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate9 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate9: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },
    member_present10: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present10 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present10: e.target.value,
        });
      },
    },
    person_name10: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name10 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name10 = value;
          return updatedData;
        });
      },
    },
    text_convocate10: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate10 || "",
    },
    role_convocate10: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate10 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate10: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },

    member_present11: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present11 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present11: e.target.value,
        });
      },
    },
    person_name11: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name11 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name11 = value;
          return updatedData;
        });
      },
    },
    text_convocate11: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate11 || "",
    },
    role_convocate11: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate11 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate10: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },

    member_present12: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present12 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present12: e.target.value,
        });
      },
    },
    person_name12: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name12 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name12 = value;
          return updatedData;
        });
      },
    },
    text_convocate12: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate12 || "",
    },
    role_convocate12: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate12 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate10: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },

    member_present13: {
      label: "Membre présent",
      type: "select",
      value: dataConvocate?.member_present13 || "", // Utilisez une valeur par défaut valide
      values: [
        { label: "Absent", value: "absent" },
        { label: "Présent", value: "present" },
        { label: "Excusé", value: "excused" },
      ],
      disabled: false,
      onChange: (e, data, setData) => {
        setData({
          ...data,
          member_present13: e.target.value,
        });
      },
    },
    person_name13: {
      label: "Personne convoquée",
      type: "text",
      value: dataConvocate?.person_name13 || "",
      onChange: (e, value, index, setData) => {
        setData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index].person_name13 = value;
          return updatedData;
        });
      },
    },
    text_convocate13: {
      type: "text",
      label: "Commentaires",
      value: dataConvocate?.text_convocate13 || "",
    },
    role_convocate13: {
      type: "select",
      label: "Fonction",
      value: dataConvocate?.role_convocate13 || "", // Utilisez une valeur par défaut
      values: [
        { label: "Arbitre", value: "referee" },
        { label: "CAG", value: "CAG" },
        { label: "CGAC", value: "CGAC" },
        { label: "Coach", value: "coach" },
        { label: "Comité d'Appel", value: "Comité d'Appel" },
        { label: "Comité Sportif", value: "Comité Sportif" },
        { label: "Conseil d'Administration", value: "CA" },
        { label: "Délégué", value: "Délégué"},
        { label: "Membre", value: "member" },
        { label: "Autre", value: "other" },
      ],
      onChange: (e, index, setData) => {
        console.log("onChange called with index:", index);
        console.log("Event value:", e.target.value);

        setData((prevData) => {
          console.log("Previous data:", prevData);

          // Vérifiez que prevData est un tableau
          if (!Array.isArray(prevData)) {
            console.warn(
              "prevData is not an array, initializing it as an empty array"
            );
            prevData = [];
          }

          // Copiez les données existantes
          const updatedData = [...prevData];

          // Mettez à jour l'élément à l'index spécifié
          if (index >= 0 && index < updatedData.length) {
            updatedData[index] = {
              ...updatedData[index],
              role_convocate10: e.target.value,
            };
            console.log("Updated data at index:", index, updatedData[index]);
          } else {
            console.warn("Index out of bounds:", index);
          }

          console.log("Updated data:", updatedData);
          return updatedData;
        });
      },
    },
  };
};

export const itemsRapport = [
  [
    "folder_number",
    "space",
    /*"session_number",*/
    "committee",
    "after",
    "committee_date",
    "next_date",
    "appel_date",
    "space",
    "sanction_description",
    "note",
    "fault_code_1_id",
    "fault_code_2_id",
    "preventive",
    "afn",
    "recidivism",
    "racism",
    // "member_present",
    // "referee_present",
    "is_convocate",
    "rapport",
    "fine",
    "arbitration_fees",
    "miscellaneous_costs",
    "total_fees",
    "official_organ_number",
    "official_organ_date",
  ],
];

export const items = [
  [
    "event_date",
    "rencontre_id",
    "equipe_id",
    "membre_id",
    "affiliation_id",
    "count",
    "color",
    "type",
    "sanction",
    "suspension_start",
    "suspension_end",
    //"sanctioned"
  ],
];

export const itemsConvoquer = [
  [
    "person_name",
    "role_convocate",
    "member_present",
    "text_convocate",
    
    "person_name2",
    "role_convocate2",
    "member_present2",
    "text_convocate2",

    "person_name3",
    "role_convocate3",
    "member_present3",
    "text_convocate3",

    "person_name4",
    "role_convocate4",
    "member_present4",
    "text_convocate4",

    "person_name5",
    "role_convocate5",
    "member_present5",
    "text_convocate5",

    "person_name6",
    "role_convocate6",
    "member_present6",
    "text_convocate6",

    "person_name7",
    "role_convocate7",
    "member_present7",
    "text_convocate7",

    "person_name8",
    "role_convocate8",
    "member_present8",
    "text_convocate8",

    "person_name9",
    "role_convocate9",
    "member_present9",
    "text_convocate9",

    "person_name10",
    "role_convocate10",
    "member_present10",
    "text_convocate10",

    "person_name11",
    "role_convocate11",
    "member_present11",
    "text_convocate11",

    "person_name12",
    "role_convocate12",
    "member_present12",
    "text_convocate12",

    "person_name13",
    "role_convocate13",
    "member_present13",
    "text_convocate13",
  ],
];

/*
  export const items_CGAC = [[
    "sanction",
    "suspension_start",
    "suspension_end",
    "cgac",
  ]];

  export const items_comite_sportif = [[
    "sanction",
    "suspension_start",
    "suspension_end",
    "comite_sportif"
  ]];

  export const items_comite_dappel = [[
    "sanction",
    "suspension_start",
    "suspension_end",
    "comite_dappel"
  ]];
  */

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
  {
    name: "event_date",
    label: "Rencontre du",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "registration_number",
    label: "Matricule",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "full_name",
    label: "Nom complet",
    options: {
      display: true,
      filter: false,
      sort: false,
    },
  },
  {
    name: "color",
    label: "Couleur",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "type",
    label: "Type",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "fault_code_1_id",
    label: "F1",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "fault_code_2_id",
    label: "F2",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "folder_number",
    label: "N° de dossier",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "count",
    label: "Nb.",
    options: {
      display: false,
      filter: true,
      sort: true,
    },
  },
  {
    name: "committee_date",
    label: "CGAC",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "next_date",
    label: "Sportif",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "appel_date",
    label: "Appel",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "points",
    label: "Pts.",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "sanction",
    label: "Sanction",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "suspension_start",
    label: "Début susp.",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "suspension_end",
    label: "Fin susp.",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "link",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
  {
    name: "delete",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomDeleteButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
];

export const structTable = (el) => {
  return {
    id: el?.id,
    registration_number: el?.affiliation?.registration_number
      ? el?.affiliation?.registration_number
      : el.registration_number,
    color: el?.color === "J" ? "Jaune" : el?.color === "R" ? "Rouge" : "",
    type: el?.type,
    fault_code_1_id: el?.faute_1?.fault_code,
    fault_code_2_id: el?.faute_2?.fault_code,
    folder_number: el?.folder_number,
    committee_date: el?.committee_date,
    next_date: el?.next_date,
    appel_date: el?.appel_date,
    event_date: el.event_date,
    full_name:
      el?.membre?.name && el?.membre?.first_name
        ? el.membre.name.toUpperCase() + " " + el.membre.first_name
        : el.full_name || "",
    count: el?.count,
    points: el?.points,
    sanction: el?.sanction,
    suspension_start: el?.suspension_start,
    suspension_end: el?.suspension_end,
  };
};

export const defaultSort = {
  name: "event_date",
  direction: "desc",
};
